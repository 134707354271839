.goto-page { position: absolute; z-index: 5; top: 0; left: 0; width: 100%; height: 100%; bottom: 0; right: 0; }

/* Opacity */
@for $i from 0 through 9 {
	.opacity-#{$i} {
		opacity: #{$i / 10} !important;
	}
}

/* Border Radius */
@for $i from 0 through 20 {
	.border-radius-#{$i} {
		border-radius: #{$i}px !important;
	}
}

/* Position: top, bottom, left, right */
@each $prop, $abbrev in (top: t, bottom: b, right: r, left: l) {
	@each $size, $length in $spacers {
		.#{$abbrev}-#{$size} {
			#{$prop}: $length !important;
		}
		.#{$abbrev}-n#{$size} {
			#{$prop}: -$length !important;
		}
	}
}

/* Font Size */
@for $i from 8 through 64 {
	.fz-#{$i} {
		font-size: #{$i}px !important;
	}
}

/* Line Height */
@for $i from 8 through 64 {
	.lh-#{$i} {
		line-height: #{$i}px !important;
	}
}
