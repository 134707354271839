/* ===== Helper */
$img-path: '../images/';
$font-path: '../fonts/';
$i: !important;
$font-name: 'avenir';
$font-sizes: xlight, light, regular, medium, bold, xbold;

/* ===== Sizes */
$box-group-padding: 140px;
$header-height: 100px;
$header-height-lg: 80px;
$header-height-sm: 60px;

/* ===== Fonts */
$fxl: $font-name + '-xlight';
$fl: $font-name + '-light';
$fr: $font-name + '-regular';
$fm: $font-name + '-medium';
$fb: $font-name + '-bold';
$fxb: $font-name + '-xbold';
$ff: $fr, 'sans-serif';

/* ===== Colors */
$light-navy-blue: #1d1d1d;
$tealish: #f3912d;


/* ===== Social Media Colors */
$facebook-color: #3b5999;
$facebook-messenger-color: #0084ff;
$twitter-color: #55acee;
$instagram-color: #e4405f;
$google-plus-color: #dd4b39;
$whatsapp-color: #25D366;
$linkedin-color: #0077B5;
$gmail-color: #c71610;
$youtube-color: #cd201f;
$sms-color: #80e27e;
