@function strip-units($number) {
	@if type-of($number) == 'number' and not unitless($number) {
		@return $number / ($number * 0 + 1);
	}

	@return $number;
}

@function rem($pxval) {
	@if not unitless($pxval) {
		$pxval: strip-units($pxval);
	}

	$base: 16;
	@if not unitless($base) {
		$base: strip-units($base);
	}
	@return ($pxval / $base) * 1rem;
}

@function em($pixels, $context: 16px) {
	@return ($pixels/$context) * 1em;
}

@mixin getFonts($fontname) {
	$fontpath: "#{$font-path}#{$fontname}/";

	@each $size in $font-sizes {
		@font-face {
			font-family: '#{$fontname}-#{$size}';
			src: url('#{$fontpath}#{$fontname}-#{$size}.woff2') format('woff2'),
			url('#{$fontpath}#{$fontname}-#{$size}.woff') format('woff'),
			url('#{$fontpath}#{$fontname}-#{$size}.svg') format('svg');
			font-weight: 400;
			font-style: normal;
			font-stretch: normal;
		}

		.text-#{$fontname}-#{$size} {
			font-family: '#{$fontname}-#{$size}';
		}
	}
}

@mixin article() {
	@extend .clearfix;

	.paragraph {
		font-size: 16px;
		font-family: $fr;
		line-height: 1.5;
	}

	h1 {
	}

	p {
		margin: 0 0 $paragraph-margin-bottom 0;
		@extend .paragraph;
	}

	b {
		font-weight: 400;
	}

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		margin-bottom: $paragraph-margin-bottom;

		li {
			@extend .paragraph;
			margin-bottom: 10px;

			&:before {
				content: '';
				display: block;
				float: left;
				width: 8px;
				height: 8px;
				background: #fff;
				margin: 8px 15px 0 0;
				border-radius: 10px;
				border: 2px solid $primary;
			}
		}
	}

	img {
		margin-bottom: 40px;
		max-width: 100%;

		&[style*=left] {
			margin-right: 50px;
		}

		&[style*=right] {
			margin-left: 50px;
		}
	}

	@include media-breakpoint-down(md) {
		img {
			width: 100%;
		}
	}
}

@mixin shortText($font-size, $line-height, $rows) {
	height: $font-size * $line-height * $rows;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: $rows;
	-webkit-box-orient: vertical;
}

@mixin centerImage($width: '', $height: '') {
	@if $width {
		width: $width;
	}
	@if $height {
		height: $height;
	}
	object-fit: cover;
	object-position: center;
}

@mixin placeholder {
	&::-webkit-input-placeholder {
		@content
	}
	&:-moz-placeholder {
		@content
	}
	&::-moz-placeholder {
		@content
	}
	&:-ms-input-placeholder {
		@content
	}
}
