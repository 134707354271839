.site-head {
}

.head-logo {
	@include media-breakpoint-down(md) {
		text-align: center;

		img {
			height: 45px;
		}
	}
}

.home-content {
}

.home-box {
	color: $body-color;

	&:hover {
		color: $primary;

		.home-box__image {
			opacity: 0.8;
		}
	}

	&__title {
		font-size: 46px;
		font-family: $fxb;
		color: $primary;
	}

	&__image {
		width: 100%;
		transition: all ease 0.3s;
	}

	&__text {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 0;
	}

	&__logo {
		font-size: 20px;
		font-family: $fxb;

		img {
			height: 24px;
		}
	}

	&__place {
		font-size: 17px;
		font-family: $fb;
		color: rgba($body-color, 0.5);
	}

}

.head-contact {
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	&__value {
		font-size: 18px;
		font-family: $fxb;
		color: $body-color;
	}

	@include media-breakpoint-down(md) {
		align-items: center;
		margin-top: 30px;
		border-top: 1px solid $gray-100;
		padding-top: 30px;
	}
}

.foot-text {
	font-size: 16px;
	font-family: $fb;
	color: rgba($body-color, 0.5);

	@include media-breakpoint-down(md) {
		text-align: center;
		margin-bottom: 30px;
	}
}

.foot-social {
	display: flex;
	align-items: center;
	justify-content: space-between;

	a {
		font-size: 16px;
		font-family: $fb;
		color: rgba($body-color, 0.5);

		&:hover {
			color: $primary;
		}
	}

	@include media-breakpoint-down(md) {
		flex-direction: column;
		text-align: center;

		a {
			padding: 5px;
		}
	}
}

h2 {
	font-size: 32px;
	font-family: $fxb;
}

p {
	font-size: 16px;
	line-height: 1.6;
	font-family: $fr;
	margin-bottom: 30px;
}
